<template>  
  <div>
    <v-card>
      <v-card-title class="text-center justify-center">
        <h3 class="font-weight-bold basil--text">
          {{$i18n.t('Usuario')}}
        </h3>
      </v-card-title>

      <v-card-text>
        <v-row v-if="loading">
          <v-col cols="12" sm="12" align="center">
            <v-progress-circular
              slot="append"
              indeterminate
              color="primary">
            </v-progress-circular>
          </v-col>
        </v-row>

        <v-row v-if="!loading">
          <v-col cols="12" sm="12" align="right">

            <v-btn
              @click="removendo = true"
              color="red"
              dark
              v-if="usuario.id">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>

            <v-btn
              @click="lista">
              <v-icon>
                mdi-view-headline
              </v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-form
          ref="form"
          v-model="valido"
          v-if="!loading"
          lazy-validation>

          <v-row>
            <v-col cols="12">
              <v-text-field
                ref="cpfCnpjIdent"
                v-model="usuario.cpfCnpjIdent"
                :rules="requiredRules"
                :label="$i18n.t('Login')"
                required>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                ref="nome"
                v-model="usuario.nome"
                :rules="requiredRules"
                :label="$i18n.t('Nome')"
                required>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="perfil == 'ADMIN'">
            <v-col cols="12" sm="12">
              <v-select
                v-model="usuario.empresa"
                :rules="requiredRules"
                item-text="nome"
                item-value="id"
                :items="empresas"
                :label="$i18n.t('Transportador')"
                required
                dense>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                ref="email"
                v-model="usuario.email"
                :rules="requiredRules"
                :label="$i18n.t('Email')"
                required>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="!usuario.id">
            <v-col cols="12">
              <v-text-field
                v-model="usuario.senha"
                :rules="requiredRules"
                type="password"
                :label="$i18n.t('Senha')"
                required>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-select
                v-model="usuario.perfil"
                :rules="requiredRules"
                :items="perfis"
                :label="$i18n.t('Perfil')"
                required
                dense>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                ref="chaveApi"
                v-model="usuario.chaveApi"
                :type="oculta ? 'password' : 'text'"
                :rules="requiredRules"
                :label="$i18n.t('ChaveApiUsuario')"
                required
                maxLength="40">
                <v-btn slot="append" icon @click="oculta = !oculta">
                  <v-icon v-if="oculta">
                    mdi-eye
                  </v-icon>
                  <v-icon v-if="!oculta">
                    mdi-eye-off
                  </v-icon>
                </v-btn>
              </v-text-field>
            </v-col>
          </v-row>

        </v-form>

        <div class="w100" v-if="!loading">
          <v-row>
            <v-col align="center" cols="12">
              <v-btn
                color="success"
                elevation="2"
                @click="salva"
                :disabled="!validateForm">
                <v-icon>
                  mdi-check
                </v-icon>
                {{$i18n.t('Salvar')}}
              </v-btn>
            </v-col>
          </v-row>
        </div>

      </v-card-text>
    </v-card>

    <v-dialog
      v-model="removendo"
      persistent
      max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{$i18n.t('RemoveUsuario')}}
        </v-card-title>
        <v-card-text>{{$i18n.t('RemoveUsuarioConfirmacao')}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="removendo = false">
            {{$i18n.t('Nao')}}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="remove">
            {{$i18n.t('Sim')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data: () => ({
      id: null,
      loading: false,
      perfil: '',
      usuario: {},
      valido: false,
      requiredRules: [
        v => !!v
      ],
      removendo: false,
      empresas: [],
      perfis: [],
      oculta: true
    }),
    computed: {
      validateForm() {
        return this.usuario
          && this.usuario.nome != '';
      }
    },
    methods: {
      load() {
        this.loading = true;
        this.$http.get(`/api/ide/usuarios/${this.id}`)
            .then((req) => {
              if (req.data.sucesso) {
                this.usuario = req.data.objeto;
                this.usuario.empresa = this.usuario.empresa.id;
                if (this.usuario == null)
                  this.$router.push('/error-403');
              }
              else
                 this.$msg.erro(req.data.mensagem);
            })
            .catch((e) => {
              this.$msg.erro(e.response.data.message);
            })
            .finally(() => {
              this.loading = false;
            });
      },
      loadTransportador() {
        this.$http.get('/api/ide/empresas')
          .then((req) => {
              this.empresas = req.data;
          })
          .catch((e) => {
            this.$msg.erro(e.response.data.message);
          });
      },
      lista() {
        this.$router.push('/usuarios');
      },
      remove() {
        this.removendo = false;
        this.loading = true;
        this.$http.delete(`/api/ide/usuarios/${this.id}`)
          .then((req) => {
            if (req.data.sucesso) {
              this.usuario = {};
              this.$msg.sucesso(this.$i18n.t('Sucesso'));
            } else
              this.$msg.erro(req.data.mensagem);
          })
          .catch((e) => {
            this.$msg.erro(e.response.data.message);
          })
          .finally(() => {
            this.loading = false;
            this.lista();
          });
      },
      salva() {
        
        if (this.$refs.form.validate()) {
          this.loading = true;

          let data = {
            "id": this.usuario.id,
            "nome": this.usuario.nome,
            "email": this.usuario.email,
            "cpfCnpjIdent": this.usuario.cpfCnpjIdent,
            "perfil": this.usuario.perfil,
            "chaveApi": this.usuario.chaveApi
          };

          if (!this.usuario.id) {
            data.senha = this.usuario.senha;
          }

          if (this.perfil == 'ADMIN')
            data.empresaId = this.usuario.empresa;

          this.$http.post('/api/ide/usuarios', data)
              .then((req) => {
                if (req.data.sucesso) {
                  this.usuario = req.data.objeto;
                  this.usuario.empresa = this.usuario.empresa.id;
                  this.$msg.sucesso(this.$i18n.t('Sucesso'));
                } else
                  this.$msg.erro(req.data.mensagem);
              })
              .catch((e) => {
                this.$msg.erro(e.response.data.message);
              })
              .finally(() => {
                this.loading = false;
                this.focus();
              });
        } else {
          this.focus();
        }
      },
      focus() {
        const self = this;
        setTimeout(function() {
          self.$refs.cpfCnpjIdent.focus();
        }, 200);
      },
      uuid() {
        return 'xxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
    },
    mounted() {
      this.id = this.$route.params.id;

      this.perfil = this.$auth.info().perfil;
      if (this.perfil == 'ADMIN') {
        this.loadTransportador();
        this.perfis = ['ADMIN', 'TRANSPORTADOR', 'EMBARCADOR', 'MOTORISTA'];          
      } else
        this.perfis = ['EMBARCADOR', 'MOTORISTA'];

      if (this.id != null) {
        if (this.id === 'add')
          this.usuario = {
            chaveApi: this.uuid()
          };
        else
          this.load();
      }

      this.focus();
    }
  }
</script>